import { graphql, useStaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby-plugin-react-i18next'
import React from 'react'
import { Footer as BulmaFooter, Container, Columns, Heading, Element, Section } from 'react-bulma-components'
import { useTranslation } from 'react-i18next'
import BrandsSection from './brands/brands-section'
import CloudinaryImage from './cloudinary/image'

const Footer = () => {
  const query = useStaticQuery(
    graphql`
      query {
        allBrand {
          edges {
            node {
              logo {
                providerId
              }
              name
              _id
              visibility {
                public
              }
            }
          }
        }
      }         
    `
  )
  const brands = query.allBrand.edges.map(edge => edge.node)

  const { t } = useTranslation()

  return(
    <>
      <Section py={5} backgroundColor='white-ter'>
        <Container>
          <Columns className='is-vcentered is-centered'>
            <Columns.Column size={2}>
              <Heading size={6} textColor='grey' textTransform='uppercase' textWeight='normal'>
                {t('components:footer.distributor')}<br /> {t('components:footer.distributor_2')}
              </Heading>
            </Columns.Column>
            <Columns.Column size={10}>
              <Container>
                <Columns className='is-vcentered is-centered is-mobile'>
                  <BrandsSection brands={brands.filter(b => b.visibility.public)}/>
                </Columns>
              </Container>
            </Columns.Column>
          </Columns>
        </Container>
      </Section>
      <BulmaFooter>
        <Container>
          <Columns gap={8}>
            <Columns.Column size={3}>
              <StaticImage src='../../static/exalto-logo.png' alt='Exalto logo' />
            </Columns.Column>
            <Columns.Column size={1}>
            </Columns.Column>
            <Columns.Column size={4}>
              <Heading size={5}>{t('components:footer.title')}</Heading>
              <Element>
                {t('components:footer.text')}
              </Element>
              <Element mt={3}>
                <ul className='is-family-monospace'>
                  <Element renderAs='li' display='flex' justifyContent='space-between' pb={3}>
                    <Element renderAs='span'>
                      <a className='exalto-link' href='/files/CGV.pdf' download>{t('components:footer.cgv')}</a>
                    </Element>
                  </Element>
                  <Element renderAs='li' display='flex' justifyContent='space-between' pb={3}>
                    <Element renderAs='span'>
                      <Link to='/rgpd' className='exalto-link'>{t('components:footer.rgpd')}</Link>
                    </Element>
                  </Element>
                </ul>
              </Element>
            </Columns.Column>
            <Columns.Column size={4}>
              <Heading size={5}>Contact</Heading>
              <ul className='is-family-monospace'>
                <Element renderAs='li' display='flex' justifyContent='space-between' pb={3}>
                  <Element renderAs='span'>e-mail </Element>
                  <a className='exalto-link' href='mailto:info@exalto.be'>info@exalto.be</a>
                </Element>
                <Element renderAs='li' display='flex' justifyContent='space-between' pb={3}>
                  <Element renderAs='span'>tel</Element>
                  <a className='exalto-link' href='tel:003223550055'>+32 2 355 00 55</a>
                </Element>
                <Element renderAs='li' display='flex' justifyContent='space-between' pb={3}>
                  <Element renderAs='span'>fax</Element>
                  <a className='exalto-link' href='tel:003223550050'>+32 2 355 00 50</a>
                </Element>
                <Element renderAs='li' display='flex' justifyContent='space-between' pb={3}>
                  <Element renderAs='span'>{t('components:footer.vat')}</Element>
                  <span>BE 0830 006 432</span>
                </Element>
              </ul>
            </Columns.Column>
          </Columns>
          <p className='is-size-7'>
            © {new Date().getFullYear()}, built by
            {' '}
            <a href='https://codefathers.be'>codefathers</a>
          </p>
        </Container>
      </BulmaFooter>
    </>
  )
}
export default Footer
