import { createHook, createStore } from 'react-sweet-state'
import { apiCall } from '../core/store'
import fileDownload from 'js-file-download'

const isBrowser = (typeof window !== 'undefined')

const PRICES_STORAGE_KEY = 'products-prices'
const LAST_PRICES_FETCH_STORAGE_KEY = 'products-prices-last-fetch'

// This is the value of the store on initialisation
const initialState = {
  endpoint: '/products',
  current: undefined,
  filters: {
    categories: [],
    brand: [],
    limit: 12,
    offset: 0,
    sort: 'name.fr',
  },
  all: [],
  prices: {},
  allPrices: {},
  lastPricesFetch: 0,
  count: 0
}

function download(dataurl, filename) {
  if (typeof document !== undefined) {
    const link = document.createElement('a')
    link.href = dataurl
    link.download = filename
    link.click()
    link.remove()
  }
}

// All the actions that mutate the store
const actions = {
  clearAll: () => async ({ setState }) => {
    setState({ all: [] })
  },
  downloadPictures: (product) => async ({ setState, getState, dispatch }) => {
    const res = await dispatch(apiCall({
      endpoint: `${getState().endpoint}/${product._id}/pictures/download`,
    }))
    console.log(product)
    download(res, product.mafactData && product.mafactData.ref)
  },
  fetch: (filters) => async ({ setState, getState, dispatch }) => {
    setState({ all: [] })
    if (!filters.kind)
      filters.kind = 'article'
    if (filters.inStock && filters.inStock[0]) {
      delete filters.inStock
      filters['stock.available>'] = 1
    }
    const res = await dispatch(apiCall({ params: {
      ...filters,
      populate: ['categories', 'pictures.item', 'attributes.item', 'attributes.item.attribute']
    } }))
    if (res && res.result) {
      setState({ all: res.result, count: res.count })
    }
  },
  get: (id) => async ({ setState, getState, dispatch }) => {
    const res = await dispatch(apiCall({
      endpoint: `${getState().endpoint}/${id}`,
      params: {
        populate: ['brand', 'categories', 'pictures.item', 'linkedProducts', 'linkedProducts.pictures.item', 'attributes.item', 'attributes.item.attribute']
      }
    }))
    res && res.result && setState({ current: res.result })
    return res.result
  },
  getProducts: (ids) => async ({ setState, getState, dispatch }) => {
    const res = await dispatch(apiCall({
      endpoint: getState().endpoint,
      params: {
        _id: ids,
        populate: ['brand', 'categories', 'pictures.item', 'linkedProducts', 'linkedProducts.pictures.item']
      }
    }))
    return res.result
  },
  setFilter: ({ key, value, clear, noSubmit }) => async ({ setState, getState, dispatch }) => {
    const filters = clear ? initialState.filters : getState().filters
    delete filters._id
    setState({
      filters: {
        ...filters,
        [key]: value
      }
    })
    if (!noSubmit) //TODO: mmmmm....
      dispatch(actions.fetch(getState().filters))
  },
  setFilters: (newFilters = []) => async ({ setState, getState, dispatch }) => {
    const { filters } = getState()
    newFilters.forEach(filter => {
      filters[filter.key] = filter.value
    })
    setState(filters)
    dispatch(actions.fetch(getState().filters))
  },
  resetFilters: (props = {}) => async ({ setState, getState, dispatch }) => {
    setState({
      filters: { ...initialState.filters }
    })
    if (!props.noSubmit)
      dispatch(actions.fetch(getState().filters))
  },
  getAllPrices: (organization, forceUpdate) => async ({ setState, getState, dispatch }) => {
    if (!organization)
      return
    const now = new Date().getTime()
    //if (!forceUpdate && now - (getState().lastPricesFetch || 0) < 1000 * 60 * 60 * 12)
    //  return
    const res = await dispatch(apiCall({
      endpoint: '/products/prices',
      method: 'post',
      cancelable: true
    }))
    if (res && res.result && Object.keys(res.result)[0]) {
      const lastPricesFetch = new Date().getTime()
      setState({
        allPrices: res.result,
        lastPricesFetch
      })
      /*if (isBrowser) {
        localStorage.setItem(PRICES_STORAGE_KEY, JSON.stringify(res.result))
        localStorage.setItem(LAST_PRICES_FETCH_STORAGE_KEY, lastPricesFetch)
      }*/
    }
  },
  getPrices: (params = []) => async ({ setState, getState, dispatch }) => {
    const res = await dispatch(apiCall({
      endpoint: '/products/prices',
      params: {
        query: params
      },
      cancelable: true
    }))
    this.setState({
      prices: res.result
    })
  },
  attemptVote: (params) => async ({ setState, getState, dispatch }) => {
    const res = await dispatch(apiCall({
      endpoint: `/products/${params.productId}/attempt-vote`,
      method: 'post',
      params,
      cancelable: true
    }))
    const product = await dispatch(actions.get(params.productId))
    const all = [...getState().all]
    const indexOfProduct = all.findIndex(p => p._id === params.productId)
    all[indexOfProduct] = product
    setState({ all })
    return res
  },
  downloadFile: (productId, fileId, fileName) => async ({ dispatch }) => {
    const res = await dispatch(apiCall({
      endpoint: `/products/${productId}/files/${fileId}`,
      method: 'GET',
      responseType: 'blob'
    }))
    if (res) fileDownload(res, fileName)
  }
}

const getInitialState = () => {
  const state = { ...initialState }
  if (!isBrowser)
    return initialState
  try {
    const prices = localStorage.getItem(PRICES_STORAGE_KEY)
    const lastPricesFetch = localStorage.getItem(LAST_PRICES_FETCH_STORAGE_KEY)
    if (prices) {
      state.prices = JSON.parse(prices)
    }
    if (lastPricesFetch) {
      state.lastPricesFetch = Number(lastPricesFetch)
    }
  } catch (err) {
    console.error('Fail to load stored products prices')
    localStorage.removeItem(PRICES_STORAGE_KEY)
  }
  return state
}


const Store = createStore({ initialState: getInitialState(), actions, name: 'products' })

export const useProductsRequest = createHook(Store, {
  selector: (state, props) => state.requests && state.requests[props.key]
})

export const useProducts = createHook(Store)

export const getCategoryAndChildrens = (categories, filteredCategories, forceAdd) => {
  categories.forEach(category => {
    const isInFilters = filteredCategories.includes(category._id)
    if (!isInFilters && forceAdd)
      filteredCategories.push(category._id)
    if (category.childrens) {
      getCategoryAndChildrens(category.childrens, filteredCategories, forceAdd || isInFilters)
    }
  })
  return filteredCategories
}