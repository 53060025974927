import React, { useState } from 'react'
import { useTranslation, Link } from 'gatsby-plugin-react-i18next'
import { Button, Container, Icon, Navbar, Tag } from 'react-bulma-components'
import { StaticImage } from 'gatsby-plugin-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCartPlus } from '@fortawesome/free-solid-svg-icons'
import { useSession } from '../../stores/session'
import SearchComponent from '../form/search-component'

const MenuBar = () => {

  const [displayMenu, setDisplayMenu] = useState(false)
  const [state] = useSession()

  const { t } = useTranslation()

  const [sessionState] = useSession()

  const productCounts = React.useMemo(() => {
    let count = 0
    sessionState.basket.forEach(b => count += Number(b.quantity))
    return count
  }, [sessionState.basket])

  return (
    <Container>
      <Navbar backgroundColor='white-ter' active={displayMenu}>
        <Navbar.Brand
          //style={{ flexBasis: '500px' }} // Total maxWidth for container on desktop
        >
          <Navbar.Item
            renderAs={Link}
            to='/'
          >

            <StaticImage src='../../../static/exalto-logo.png' alt='Exalto logo' width={112} objectFit='contain' />

          </Navbar.Item>
          <Navbar.Item
            style={{ flexGrow: 1, flexShrink: 1 }} // Allow this item to grow on mobile
          >
            <SearchComponent
              width='100%'
              style={{ flexBasis: '100%' }}
            />
          </Navbar.Item>
          <Navbar.Item renderAs={Link} desktop={{ display: 'hidden' }} to='/basket' arrowless textColor='primary'>
            <Icon>
              <FontAwesomeIcon icon={faCartPlus} />
            </Icon>
          </Navbar.Item>

          <Navbar.Burger onClick={() => setDisplayMenu(!displayMenu)} />
        </Navbar.Brand>
        <Navbar.Menu>
          <Navbar.Container align={'right'}>
            <Navbar.Item renderAs={Link} hoverable={true} to='/' arrowless textColor='black'>
              {t('components:header.nav_menu.home')}
            </Navbar.Item>
            { sessionState && sessionState.currentUser &&
              <Navbar.Item renderAs={Link} hoverable={true} to='/attempts' arrowless textColor='black'>
                {t('components:header.nav_menu.attempts')}
              </Navbar.Item>
            }
            <Navbar.Item renderAs={Link} hoverable={true} to='/all-products' arrowless textColor='black'>
              {t('components:header.nav_menu.e_shop')}
            </Navbar.Item>
            {
              sessionState.currentOrganizationRole !== 'organization/commercials' &&
                <Navbar.Item renderAs={Link} to='/basket' arrowless textColor='primary'>
                  <Icon>
                    <FontAwesomeIcon icon={faCartPlus} />
                  </Icon>
                  <Tag textColor='primary' textSize={7}>({productCounts})</Tag>
                </Navbar.Item>
            }
            <Navbar.Item renderAs={Link} to={state.isAuthenticated ? '/account/dashboard' : '/login'}>
              <Button color='primary'>
                {
                  state.isAuthenticated
                    ?
                    state.currentUser.firstName
                    :
                    t('components:header.nav_menu.account')
                }
              </Button>
            </Navbar.Item>
            {/* <Navbar.Item renderAs={Link} desktop={{ display: 'hidden' }}>
              Présentation
            </Navbar.Item>
            <Navbar.Item renderAs={Link} desktop={{ display: 'hidden' }}>
              Contact
            </Navbar.Item>
            <Navbar.Item renderAs={Link} desktop={{ display: 'hidden' }}>
              Partenaires
            </Navbar.Item>
            <Navbar.Item renderAs={Link} desktop={{ display: 'hidden' }}>
              News
            </Navbar.Item> */}
          </Navbar.Container>
        </Navbar.Menu>
      </Navbar>
    </Container>
  )
}
export default MenuBar