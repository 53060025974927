import * as React from 'react'
import { Image, Transformation, CloudinaryContext } from 'cloudinary-react'

const CloudinaryImage = ({
  publicId,
  transformations,
  quality,
  format
}) => (
  <CloudinaryContext cloudName='dkgryqpim'>
    <Image
      publicId={publicId || 'no_picture'}
      format={ format || 'jpg' }
      secure='true'
      loading='lazy'
      quality={ quality || 'auto' }
    >
      {transformations && <Transformation {...transformations} />}
    </Image>
  </CloudinaryContext>
)

export default CloudinaryImage
