/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import CookieConsent from 'react-cookie-consent'
import { useLocation } from '@reach/router'
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'
import { useTranslation, Link, useI18next } from 'gatsby-plugin-react-i18next'
import '../styles/styles.scss'
import Header from './header'
import Footer from './footer'
import {
  Breadcrumb,
  Container,
  Element,
  Section,
  Tabs,
} from 'react-bulma-components'
import { useSession } from '../stores/session'
import queryString from 'query-string'
import { useProducts } from '../stores/products'
import Seo from './seo'

const Layout = ({ breadcrumbs, pro, children, anonymous, logged }) => {
  const location = useLocation()
  const { t } = useTranslation()
  const [sessionState, sessionActions] = useSession()
  const [productsState, productsActions] = useProducts()

  const { navigate } = useI18next()

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  //Redirection if a connected user accesses a page intended for the guest
  React.useEffect(() => {
    if (anonymous && sessionState.initialized && sessionState.isAuthenticated) {
      const query = queryString.parse(location.search)
      if (query.redirect)
        query.redirect = query.redirect.replace(/^\/en|\/fr|\/nl/, '')
      navigate(query.redirect || '/')
    }
    if (logged && sessionState.initialized && !sessionState.isAuthenticated)
      navigate(`/login?redirect=${location.pathname}`)
  }, [
    anonymous,
    logged,
    sessionState.initialized,
    sessionState.isAuthenticated,
  ])

  //Check if session and token are already valid
  React.useEffect(() => {
    if (!sessionState.initialized) sessionActions.isAuth()
  }, [sessionState.initialized, sessionState.token])

  React.useEffect(() => {
    sessionActions.getOrganization()
  }, [
    sessionState.currentOrganization && sessionState.currentOrganization.item,
  ])

  React.useEffect(() => {
    if (
      sessionState.currentOrganization &&
      sessionState.currentOrganization.organization &&
      sessionState.currentOrganizationRole !== 'organization/commercials' &&
      (!productsState.requests ||
        !productsState.requests['post /products/prices'] ||
        productsState.requests['post /products/prices'].status !== 'inProgress')
    )
      productsActions.getAllPrices(
        sessionState.currentOrganization.organization
      )
  }, [
    sessionState.currentOrganization &&
      sessionState.currentOrganization.organization,
  ])

  return (
    <>
      <Header />
      <Seo
        title={`Exalto - ${location.pathname
          .split(/[.,\/ -]/)
          .map((s, index) =>
            s && index > 0 ? s[0].toUpperCase() + s.slice(1) : ''
          )
          .slice(2)
          .join(' ')}`}
      />
      {pro ? (
        <Element style={{ borderBottom: '#dbdbdb solid 1px' }}>
          <Container>
            <Tabs>
              <Element
                className='is-size-7-mobile'
                active
                to='/account/dashboard'
                renderAs={Link}
              >
                Dashboard
              </Element>
              {

                sessionState.currentOrganizationRole !== 'organization/commercials' &&
                  <>
                    <Element
                      className='is-size-7-mobile'
                      to='/account/invoices'
                      renderAs={Link}
                    >
                      {t('invoices:title')}
                    </Element>
                    <Element
                      className='is-size-7-mobile'
                      to='/account/orders'
                      renderAs={Link}
                    >
                      {t('orders:title')}
                    </Element>
                  </>
              }
              {/*<Element to='/account/credit-notes' renderAs={Link}>Notes de crédit</Element>*/}
              { /*<Element
                className='is-size-7-mobile'
                to='/account/sav'
                renderAs={Link}
              >
                SAV
      </Element>*/}
              <Element
                className='is-size-7-mobile'
                to='/account/profile'
                renderAs={Link}
              >
                {t('account:profile.title')}
              </Element>
              <Element
                className='is-size-7-mobile'
                onClick={() => sessionActions.logout()}
              >
                {t('account:logout')}
              </Element>
            </Tabs>
          </Container>
        </Element>
      ) : undefined}
      {breadcrumbs && breadcrumbs[0] ? (
        <Section my={0}>
          <Container>
            <Breadcrumb size='small' separator='arrow'>
              {breadcrumbs.map((breadcrum, index) => (
                <Breadcrumb.Item key={index}>
                  <Element
                    textColor='grey-dark'
                    onClick={breadcrum.onClick}
                    to={breadcrum.to}
                    renderAs={breadcrum.to ? Link : 'a'}
                  >
                    {breadcrum.label}
                  </Element>
                </Breadcrumb.Item>
              ))}
            </Breadcrumb>
          </Container>
        </Section>
      ) : undefined}
      <main>{children}</main>
      <Footer />
      <CookieConsent
        location='bottom'
        buttonText={`✔ ${t('components:cookie_banner.accept_button')}`}
        enableDeclineButton
        declineButtonText={`✘  ${t('components:cookie_banner.decline_button')}`}
        cookieName='gatsby-gdpr-google-analytics'
        disableButtonStyles={true}
        buttonClasses='button is-small is-success has-text-weight-bold mx-3 my-2'
        declineButtonClasses='button is-small is-danger has-text-weight-bold mx-3 my-2'
        buttonWrapperClasses='is-flex is-flex-direction-column-reverse'
        containerClasses='is-align-items-center is-justify-content-center'
        onAccept={() => {
          initializeAndTrack(location)
        }}
      >
        <p className='title is-6 has-text-white mb-3'>
          {t('components:cookie_banner.title')}
        </p>
        <p className='is-size-7'>
          {t('components:cookie_banner.content')}
          <br />
          <Link
            to={'/privacy-policy/'}
            className='has-text-white'
            style={{ textDecoration: 'underline' }}
          >
            {t('components:cookie_banner.link_privacy_policy')}
          </Link>
        </p>
      </CookieConsent>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
