import { useStaticQuery, graphql } from 'gatsby'
import { useTranslation, useI18next, Link } from 'gatsby-plugin-react-i18next'

const useLanguageSelector = () => {

  const { i18n } = useTranslation()
  const { languages, originalPath } = useI18next()

  const allSlugsFromGraphql = useStaticQuery(
    graphql`
        query MyQuery {
          allLocale(filter: {ns: {eq: "slugs"}}) {
            edges {
              node {
                language
                data
                ns
              }
            }
          }
        }    
        `
  )

  const allSlugs = {}
  allSlugsFromGraphql.allLocale.edges.forEach(
    ({ node: { data, language, ns } }) => {
      allSlugs[language] = (JSON.parse(data))['slugs']
    }
  )

  const currentLanguage = i18n.language
  const otherLanguages = languages.filter(language => language !== currentLanguage)

  return (
    {
      currentLanguage: currentLanguage,
      otherLanguages: (
        otherLanguages.map(
          currentOtherLanguage => (
            {
              link: allSlugs[currentOtherLanguage][originalPath] ? allSlugs[currentOtherLanguage][originalPath] : originalPath,
              language: currentOtherLanguage
            }
          )
        )
      )
      ,
      Link: Link
    }
  )
}

export default useLanguageSelector