import React from 'react'
import { Form, Icon, Element, Columns } from 'react-bulma-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import Autosuggest from 'react-autosuggest'
import useDebounce from '../../hooks/use-debounce'
import { useSearch } from '../../stores/search'
import Highlighter from 'react-highlight-words'
import CloudinaryImage from '../cloudinary/image'
import { useProducts } from '../../stores/products'
import { useI18next } from 'gatsby-plugin-react-i18next'
import { useLocation } from '@reach/router'

const renderSuggestion = (suggestion, value) => (
  <div>
    {
      (suggestion.isCategory || suggestion.isBrand) ?
        <>
          { suggestion.isCategory && 'Catégorie:'}
          { suggestion.isBrand && 'Marque:'}
          &nbsp;
          <Highlighter
            highlightClassName='highlight'
            searchWords={[value]}
            autoEscape={true}
            textToHighlight={suggestion.label || ''}
          />
        </>
        :
        <Columns>
          <Columns.Column size={2}>
            <CloudinaryImage
              publicId={suggestion.pictures[0] && suggestion.pictures[0].item.providerId}
              transformations={{
                width: '120',
                height: '120',
                crop: 'thumb',
              }}
            />
          </Columns.Column>
          <Columns.Column>
            <Element renderAs='p'>
              { suggestion.label }
            </Element>
            <Element renderAs='p'>
              <small>
                { suggestion.mafactData.ref }
              </small>
            </Element>
          </Columns.Column>
        </Columns>
    }
  </div>
)

const SearchComponent = ({
  placeholder = 'Baffle bluetooth, trottinette électrique,...',
  onlyProduct,
  onChange,
  ...rest
}) => {
  const [searchComponentValue, setSearchComponentValue] = React.useState('')
  const debouncedSearch = useDebounce(searchComponentValue, 100)
  const [state, actions] = useSearch()
  const [productsState, productsActions] = useProducts()

  const [initialized, setInitialized] = React.useState(false)

  React.useEffect(() => {
    if (initialized)
      actions.search(searchComponentValue)
    else
      setInitialized(true)
  }, [debouncedSearch])

  const { navigate } = useI18next()
  const location = useLocation()

  const suggestions = React.useMemo(() => {
    if (!onlyProduct) return state.result
    return state.result.filter(i => i.isProduct)
  }, [state.result, onlyProduct])

  return (
    <Form.Control {...rest}>
      <Icon align='left' size='small'>
        <Element renderAs={FontAwesomeIcon} icon={faSearch} textColor='primary' />
      </Icon>
      <Autosuggest
        suggestions={suggestions || []}
        onSuggestionsFetchRequested={() => null}
        onSuggestionsClearRequested={(value) => value}
        getSuggestionValue={() => searchComponentValue}
        renderSuggestion={(suggestion) => renderSuggestion(suggestion, searchComponentValue)}
        inputProps={{
          value: searchComponentValue,
          className: 'input',
          onChange: (e, { newValue }) => setSearchComponentValue(newValue),
          onKeyPress: (e) => {
            if (!onChange && e.key === 'Enter') {
              productsActions.setFilter({
                key: 'search',
                value: searchComponentValue || undefined,
                clear: true
              })
            }
          }
        }}
        onSuggestionSelected={ (e, { suggestion }) => {
          if (onChange)
            return onChange(suggestion)
          if (suggestion.isCategory)
            productsActions.setFilter({ key: 'categories', value: [suggestion.value], clear: true })
          else if (suggestion.isBrand)
            productsActions.setFilter({ key: 'brand', value: [suggestion.value], clear: true })
          if (suggestion.isProduct && !location.pathname.match(`/products/${suggestion.value}`))
            navigate(`/products/${suggestion.value}`)
          else if (!location.pathname.match('all-products'))
            navigate('/all-products')
        } }
      />
      { /*<Form.Input
        value={searchComponentValue}
        onChange={e => setSearchComponentValue(e.target.value)}
        placeholder={placeholder}
      >
      </Form.Input>*/ }
    </Form.Control>
  )
}

export default SearchComponent