import axios from 'axios'

const isBrowser = (typeof window !== 'undefined')

const api = axios.create({
  baseURL: process.env.API_HOST,
})

const apiCall = async ({ endpoint, params, method, headers = {}, cancelToken }) => {

  let session = isBrowser && localStorage.getItem('session')

  if (session) {
    session = JSON.parse(session)
    headers.authorization = session.token
    headers.organization = session.currentOrganization && session.currentOrganization.item
  }

  const config = {
    method: method || 'get',
    url: endpoint,
    headers,
    cancelToken
  }

  if (config.method === 'get' && params) config.params = params
  else if (params) config.data = params

  try {
    const res = await api.request(config)
    return res
  } catch (err) {
    if (axios.isCancel(err))
      return '__cancel__'
    return err.response
  }
}

export default apiCall