import { isNaN } from 'lodash'
import { createHook, createStore } from 'react-sweet-state'
import { apiCall } from '../core/store'

const isBrowser = (typeof window !== 'undefined')

// This is the value of the store on initialisation
const initialState = {
  initialized: false,
  isAuthenticated: false,
  currentUser: undefined,
  currentOrganization: undefined,
  currentOrganizationRole: undefined,
  currentOrganizationTurnover: undefined,
  token: undefined,
  requests: {},
  basket: []
}

let lastGetBasketPricesCall

const privateActions = {
  setLoggedStateFromApiResponse: (apiResponse) => ({ setState }) => {
    if (apiResponse && apiResponse.ok) {
      const newState = {
        initialized: true,
        isAuthenticated: true,
        currentUser: apiResponse.result.user,
        token: apiResponse.result.token,
        currentOrganization: apiResponse.result.user.organizations[0],
        currentOrganizationRole: apiResponse.result.user.organizations[0] && apiResponse.result.user.organizations[0].role
      }
      setState(newState)
      isBrowser && localStorage.setItem('session', JSON.stringify(newState))
    } else {
      setState({ initialized: true })
    }
  }
}

// All the actions that mutate the store
const actions = {
  login: (params) => async ({ setState, getState, dispatch }) => {
    const res = await dispatch(
      apiCall({
        endpoint: '/auth',
        params,
        method: 'post'
      })
    )
    dispatch(privateActions.setLoggedStateFromApiResponse(res))
  },
  logout: () => ({ setState }) => {
    setState({ ...initialState, initialized: true })
    isBrowser && localStorage.removeItem('session')
    if (isBrowser)
      window.location = '/'
  },
  isAuth: (params) => async ({ setState, getState, dispatch }) => {
    const token = getState().token
    if (!token)
      return setState({ initialized: true })
    const res = await dispatch(
      apiCall({
        endpoint: '/is-auth',
        params: { token },
        method: 'get'
      })
    )
    dispatch(privateActions.setLoggedStateFromApiResponse(res))
  },
  //TODO: Utiliser un store users et y passer cette action?
  requestNewPassword: (params) => async ({ setState, getState, dispatch }) => {
    await dispatch(
      apiCall({
        endpoint: '/users/password-recovery',
        params,
        method: 'post'
      })
    )
  },
  resetPassword: (params) => async ({ setState, getState, dispatch }) => {
    const res = await dispatch(
      apiCall({
        endpoint: '/users/reset-password',
        params,
        method: 'post'
      })
    )
    if (res && res.result && res.result.token)
      await dispatch(actions.isAuth({ token: res.result.token }))
  },
  completeProfile: (params) => async ({ dispatch }) => {
    await dispatch(
      apiCall({
        endpoint: '/users/complete-profile',
        method: 'post',
        params,
      }),
    )
  },
  addItemToBasket: ({ item, quantity }) => async ({ setState, getState, dispatch }) => {
    const basket = [...getState().basket]
    const existingItem = basket.find(v => v.item === item)
    quantity = Number(quantity)
    if (isNaN(quantity))
      return
    if (existingItem && quantity)
      existingItem.quantity = quantity
    else if (existingItem && quantity === 0)
      return dispatch(actions.removeItemFromBasket({ item }))
    else if (quantity)
      basket.push({ item, quantity })
    setState({ basket })
    dispatch(actions.storeBasket())
    dispatch(actions.loadBasket())
    //dispatch(actions.loadBasketPrices(/*[{ _id: item, quantity: Number(quantity) }]*/))
  },
  removeItemFromBasket: ({ item }) => async ({ setState, getState, dispatch }) => {
    const basket = [...getState().basket]
    setState({ basket: basket.filter(b => b.item !== item) })
    dispatch(actions.storeBasket())
  },
  loadBasket: () => async ({ setState, getState, dispatch }) => {
    let basket = [...getState().basket]
    const ids = basket.map(v => v.item)
    const res = await dispatch(apiCall({ endpoint: '/products', params: {
      populate: ['categories', 'pictures.item', 'brand'],
      _id: ids
    } }))
    if (res && res.result) {
      const prices = await dispatch(apiCall({
        endpoint: '/products/prices',
        method: 'post',
        params: {
          productIds: ids
        },
        cancelable: true
      }))
      basket.forEach((basket) => {
        const product = res.result.find(product => product._id === basket.item)
        basket.product = product
        basket.pricing = product && prices && prices.result && prices.result[product._id]
      })
      basket = basket.filter(b => b.product)
      setState({ basket })
      dispatch(actions.storeBasket())
      //await dispatch(actions.loadBasketPrices())
    }
  },
  loadBasketPrices: (products) => async ({ setState, getState, dispatch }) => {
    const now = new Date().getTime()
    lastGetBasketPricesCall = now
    setTimeout(async () => {
      if (now === lastGetBasketPricesCall) {
        const basket = [...getState().basket]
        const res = await dispatch(apiCall({
          endpoint: '/products/prices',
          method: 'post',
          params: {
            products: products || basket.map(b => ({ _id: b.item, quantity: Number(b.quantity) }))
          },
          cancelable: true
        }))
        basket.forEach((basket) => {
          basket.price = res && res.result && res.result[basket.item] ? res.result[basket.item] : 'N/A'
        })
        setState({ basket })
        dispatch(actions.storeBasket())
      }
    }, 500)
  },
  clearBasket: () => ({ setState, getState, dispatch }) => {
    setState({ basket: [] })
    dispatch(actions.storeBasket())
  },
  storeBasket: () => ({ getState }) => {
    const { basket } = getState()
    localStorage.setItem('basket', JSON.stringify(basket))
  },
  getOrganization: () => async ({ setState, getState, dispatch }) => {
    const { currentOrganization } = getState()
    if (!currentOrganization)
      return
    const res = await await dispatch(
      apiCall({
        endpoint: `/organizations/${currentOrganization.item}`,
        method: 'get'
      })
    )
    if (res && res.result) {
      const { currentOrganization } = getState()
      setState({
        currentOrganization: {
          organization: res.result,
          ...currentOrganization,
        }
      })
    }
  },
  getOrganizationStats: () => async ({ setState, getState, dispatch }) => {
    const { currentOrganization } = getState()
    if (!currentOrganization)
      return
    const res = await await dispatch(
      apiCall({
        endpoint: `/organizations/${currentOrganization._id}/stats`,
        method: 'get'
      })
    )
    if (res && res.result) {
      setState({
        currentOrganizationStats: res.result
      })
    }
  },
  getOrganizationTurnover: () => async ({ setState, getState, dispatch }) => {
    const { currentOrganization } = getState()
    if (!currentOrganization)
      return
    const res = await await dispatch(
      apiCall({
        endpoint: `/organizations/${currentOrganization._id}/turnover`,
        method: 'get'
      })
    )
    if (res && res.result) {
      setState({
        currentOrganizationTurnover: res.result.turnover
      })
    }
  },
  registerAsPro: (params) => async ({ setState, getState, dispatch }) => {
    await dispatch(
      apiCall({
        endpoint: '/users/register-as-pro',
        params,
        method: 'post'
      })
    )
  },
  sendSavContact: (params) => async ({ setState, getState, dispatch }) => {
    const { currentOrganization } = getState()
    if (!currentOrganization)
      return
    await await dispatch(
      apiCall({
        endpoint: `/organizations/${currentOrganization._id}/sav-contact`,
        method: 'post',
        params
      })
    )
  },
  updateOrganization: (params) => async ({ setState, getState, dispatch }) => {
    await dispatch(
      apiCall({
        endpoint: `/organizations/${getState().currentOrganization.item}`,
        params,
        method: 'put'
      })
    )
  },
  updateUser: (params) => async ({ setState, getState, dispatch }) => {
    await dispatch(
      apiCall({
        endpoint: `/users/${getState().currentUser._id}`,
        params,
        method: 'put'
      })
    )
    await dispatch(actions.isAuth())
  },
  clearRequest: (params = {}) => ({ getState, setState }) => {
    const requests = { ...getState().requests }
    delete requests[params.key]
    setState({ requests })
  }
}

const getInitialState = () => {
  let state = {}
  if (!isBrowser)
    return initialState
  if (isBrowser && localStorage.getItem('session')) {
    state = {
      ...JSON.parse(localStorage.getItem('session'))
    }
  } else {
    state = { ...initialState }
  }
  if (isBrowser && localStorage.getItem('basket')) {
    state = { ...state, basket: JSON.parse(localStorage.getItem('basket')) }
  } else {
    state.basket = []
  }

  return state
}

const Store = createStore(
  {
    initialState: getInitialState(),
    actions,
    name: 'session'
  }
)

export const useSessionRequest = createHook(Store, {
  selector: (state, props) => state.requests && state.requests[props.key]
})

export const useSession = createHook(Store)

