import { createHook, createStore } from 'react-sweet-state'
import { apiCall } from '../core/store'

// This is the value of the store on initialisation
const initialState = {
  value: '',
  result: [],
}

// All the actions that mutate the store
const actions = {
  search: (search, language = 'fr') => async ({ setState, dispatch }) => {
    const [productsRes, categoriesRes, brandsRes] = await Promise.all([
      await dispatch(apiCall({
        endpoint: '/products',
        params: {
          search,
          populate: ['categories', 'pictures.item'],
          limit: 10,
          fields: ['_id', `name.${language}`, 'pictures', 'mafactData.ref', 'categories']
        }
      })),
      await dispatch(apiCall({
        endpoint: '/categories',
        params: {
          search,
          limit: 10,
          fields: ['_id', `labels.${language}`]
        }
      })),
      await dispatch(apiCall({
        endpoint: '/brands',
        params: {
          search,
          limit: 10,
          fields: ['_id', 'name']
        }
      }))
    ])
    if (productsRes && categoriesRes && productsRes.result && categoriesRes.result)
      setState({
        value: search,
        result: [
          ...categoriesRes.result.map(category => ({
            value: category._id,
            label: category.labels[language],
            isCategory: true,
            ...category
          })),
          ...brandsRes.result.map(brand => ({
            value: brand._id,
            label: brand.name,
            isBrand: true,
            ...brand
          })),
          ...productsRes.result.map(product => ({
            value: product._id,
            label: product.name[language],
            isProduct: true,
            ...product
          })),
        ]
      })
  }
}

const Store = createStore({ initialState, actions, name: 'search' })

export const useSearchRequest = createHook(Store, {
  selector: (state, props) => state.requests && state.requests[props.key]
})

export const useSearch = createHook(Store)
