import React from 'react'
import { Element } from 'react-bulma-components'
import TopBar from './top-bar'
import MenuBar from './menu-bar'

const Header = () => (
  <Element backgroundColor='white-ter'>
    <TopBar />
    <MenuBar />
  </Element>
)



export default Header
