import React from 'react'
import { Element, Container, } from 'react-bulma-components'
import useLanguageSelector from '../../hooks/use-language-selector'

const TopBar = () => {

  const languageSelector = useLanguageSelector()

  return (
    <Element backgroundColor='dark' textColor='white' px={3}>
      <Container display='flex' justifyContent='space-between' py={2} textSize={7}>
        <Element>
          <Element textColor='white' mr={3} textWeight='bold' renderAs='span' display='inline-block' style={{ borderBottom: '1px solid white' }}>
            {
              languageSelector.currentLanguage
            }
          </Element>
          {
            languageSelector.otherLanguages.map(
              currentOtherLanguage => (
                <Element textColor='white' mr={3}
                  key={currentOtherLanguage.language} renderAs={languageSelector.Link}
                  to={currentOtherLanguage.link}
                  language={currentOtherLanguage.language}>{currentOtherLanguage.language}
                </Element>
              )
            )
          }
          <Element renderAs='a' textColor='white' href='tel:003223550055' pr={3}>
            +32 2 355 00 55
          </Element>
          <Element renderAs='a' textColor='white' href='mailto:info@exalto.be'>
            info@exalto.be
          </Element>
        </Element>
        {/* All that follows will be hidden on mobile and tablet */}
        <Element touch={{ display: 'hidden' }}>
          {/* <Element pl={3} textColor='white' renderAs={Link}>Présentation</Element> */}
          {/* <Element pl={3} textColor='white' renderAs={Link}>Contact</Element> */}
          {/* <Element pl={3} textColor='white' renderAs={Link}>Partenaires</Element>*/}
          {/* <Element pl={3} textColor='white' renderAs={Link}>News</Element> */}
        </Element>
      </Container>
    </Element>
  )
}
export default TopBar