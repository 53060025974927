import * as React from 'react'

export default (value, timeout = 1000) => {
  const [state, setState] = React.useState(value)

  React.useEffect(() => {
    const handler = setTimeout(() => setState(value), timeout)
    return () => clearTimeout(handler)
  }, [value, timeout])

  return state
}
