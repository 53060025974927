import { graphql, useStaticQuery } from 'gatsby'
import { sortBy } from 'lodash'
import React from 'react'
import { Columns } from 'react-bulma-components'
import CloudinaryImage from '../cloudinary/image'

const BrandsSection = ({ isGray }) => {

  const query = useStaticQuery(
    graphql`
      query {
        allBrand {
          edges {
            node {
              logo {
                providerId
              }
              visibility {
                public
              }
              name
              _id
              link
            }
          }
        }
      }         
    `
  )
  const brands = sortBy(query.allBrand.edges.map(edge => edge.node).filter(b => b.visibility.public), 'name')

  return brands.map(brand =>
    <Columns.Column className='is-mobile is-3-mobile is-2-tablet' key={brand._id} textAlign='center'>
      <a href={brand.link} target='_blank' rel='noreferrer'>
        <CloudinaryImage
          publicId={ brand.logo.providerId }
          format='png'
          transformations={{
            height: '70',
            crop: 'pad',
            effect: isGray && 'grayscale'
          }}/>
      </a>
    </Columns.Column>
  )
}


export default BrandsSection