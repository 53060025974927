import api from './api'
import { defaults } from 'react-sweet-state'
import axios from 'axios'
defaults.devtools = (process.env.NODE_ENV === 'development')

function timeout(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export const apiCall = (config) => async ({ setState, getState, dispatch }) => {

  if (!config.endpoint) config.endpoint = getState().endpoint

  const { requests } = getState()
  const requestKey = config.requestKey || `${config.method || 'get'} ${config.endpoint}`

  const inProgressState = {
    requests: { ...requests, [requestKey]: { status: 'inProgress' } },
  }
  let existingCall
  if (config.cancelable) {
    const callCancelTokens = getState().callCancelTokens || {}
    existingCall = callCancelTokens[requestKey]
    if (existingCall)
      await callCancelTokens[requestKey].cancel()

    const cancelToken = axios.CancelToken.source()
    config.cancelToken = cancelToken.token
    inProgressState.callCancelTokens = { ...callCancelTokens, [requestKey]: cancelToken }
  }

  if (existingCall)
    await timeout(200)

  setState(inProgressState)

  const res = await api(config)
  if (!res || res.status >= 400) {
    setState({
      requests: {
        ...requests,
        [requestKey]: {
          status: 'error',
          data: res,
          message: res && res.data && res.data.data && res.data.data.message
        }
      }
    })
  } else if (res !== '__cancel__'){
    const successState = { requests: { ...requests, [requestKey]: { status: 'success', data: res } } }
    if (config.cancelable) {
      const callCancelTokens = getState().callCancelTokens || {}
      successState.callCancelTokens = {
        ...callCancelTokens, [requestKey]: undefined
      }
    }
    setState(successState)
    return res.data
  }
}


const logger = storeState => next => arg => {
  console.log(storeState.key, 'payload: ', arg, storeState)
  console.log(storeState.key, ':', storeState.getState())
  return next(arg)
}

//defaults.middlewares.add(logger)